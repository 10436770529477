import { ReactNode, useRef } from 'react';

interface PageSpotter {
  children?: ReactNode;
  page: number;
}

export default function PageSpotter({ children, page }: PageSpotter) {
  const pageNumber = useRef(page);

  return (
    <div className="pg-article" data-page={pageNumber.current}>
      {children}
    </div>
  );
}
