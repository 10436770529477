/* eslint-disable react/no-array-index-key */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable camelcase */
// @refresh reset
import WrapperContentLastNews from '@components/Layout/WrapperContentLastNews';
import { TimeLine } from '@/types/types';
import PageNewsWithMimir, { routeTypes } from '@/components/Layout/LastNews/mimir';
import WrapperContent from '@/components/Layout/WrapperContent';
import RecentNewsButton from '@/components/Layout/RecentNewsButton';
import Titulo from '@/components/Layout/Title';
import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getCurrentHub } from '@sentry/nextjs';
import { mglGray } from '@/styles/designSystemColors';
import { enableSponsoredCategories, sponsoredCategoryType } from '@/lib/sponsors';
import { SponseredCategoryHeader } from '@/components/Layout/SponsoredCategoryHeader';

interface FeaturedLastNewsProps {
  itens: TimeLine[];
  paginacao: string;
}

interface LastNewsData {
  featuredLastNewsData?: FeaturedLastNewsProps;
  rootPage: string | string[];
  textTitle: string;
  route?: string;
  routeType?: (typeof routeTypes)[number];
  initialPage?: string | string[];
  recentButton?: boolean;
  headingTag?: 'h1' | 'h2';
  noPagination?: boolean;
  useServerSide?: boolean;
  sponsoredCategory?: sponsoredCategoryType;
}

export default function FeaturedLastNews({
  featuredLastNewsData = null,
  rootPage,
  textTitle,
  initialPage,
  route,
  routeType,
  recentButton = false,
  headingTag = 'h2',
  noPagination = false,
  useServerSide = true,
  sponsoredCategory = undefined,
}: LastNewsData) {
  const currentSpan = getCurrentHub().getScope()?.getSpan();
  const featuredLastNewsSpan = currentSpan && currentSpan.startChild({ op: 'featuredLastNews' });

  const [noItemsFound, setNoItemsFound] = useState(false);
  const router = useRouter();
  useEffect(() => {
    setNoItemsFound(false);
  }, [router]);

  return (
    !noItemsFound && (
      <WrapperContentLastNews backgroundColor={'bg-mglGray50'}>
        <WrapperContent backgroundColor={mglGray.mglGray50} isOnHeader>
          {enableSponsoredCategories.includes(sponsoredCategory) && <SponseredCategoryHeader sponsoredCategory={sponsoredCategory} />}
          <Titulo texto={textTitle} color="black" headingTag={headingTag} />
        </WrapperContent>
        {recentButton && <RecentNewsButton link={rootPage.toString()} />}
        <PageNewsWithMimir
          posts={featuredLastNewsData?.itens || []}
          paginacao={featuredLastNewsData?.paginacao || ''}
          rootPage={rootPage}
          initialPage={initialPage}
          route={route}
          routeType={routeType}
          noPagination={noPagination}
          setNoItemsFound={setNoItemsFound}
          useServerSide={useServerSide}
        />
        <>{featuredLastNewsSpan && featuredLastNewsSpan.finish()}</>
      </WrapperContentLastNews>
    )
  );
}
