import { partHtml } from '@/pages/[categorias]/[...news]';

type ContentRearrangerProps = {
  content: partHtml[];
  isAmp?: boolean;
  useTruvid?: boolean;
  useMembrana?: boolean;
  truvidOptions?: {
    enableMobile?: boolean;
    enableDesktop?: boolean;
    positionMobile?: number;
    positionDesktop?: number;
  };
  membranaOptions?: {
    enableMobile?: boolean;
    enableDesktop?: boolean;
    positionMobile?: number;
    positionDesktop?: number;
  };
  adsOptions?: {
    enableMobile?: boolean;
    enableDesktop?: boolean;
    positionMobile?: number;
    positionDesktop?: number;
  };
  ampAdFlying?: {
    enable: boolean;
    position: number;
  };
  shouldUseIdCountPosition?: boolean;
};

export function contentRearranger({
  content,
  isAmp = false,
  useTruvid = true,
  useMembrana = false,
  adsOptions = { enableMobile: true, enableDesktop: true, positionMobile: 4, positionDesktop: 4 },
  truvidOptions = { enableMobile: true, enableDesktop: true, positionMobile: 1, positionDesktop: 2 },
  membranaOptions = { enableMobile: true, enableDesktop: true, positionMobile: 1, positionDesktop: 2 },
  ampAdFlying = { enable: true, position: 1 },
  shouldUseIdCountPosition = true,
}: ContentRearrangerProps) {
  const length = content?.length;
  const maxIdCountPosition = 10;

  if (length < adsOptions.positionMobile && length < adsOptions.positionDesktop) return content;

  const isValidDesktop = (i = +1) => i % adsOptions.positionDesktop === 0 && i >= adsOptions.positionDesktop && i < length && adsOptions.enableDesktop;
  const isValidMobile = (i = +1) => i % adsOptions.positionMobile === 0 && i >= adsOptions.positionMobile && i < length && adsOptions.enableMobile;

  const shouldRenderTruvidMobile = (i = +1) =>
    !isAmp && useTruvid && truvidOptions.enableMobile && i === adsOptions.positionMobile * truvidOptions.positionMobile;
  const shouldRenderMembranaMobile = (i = +1) =>
    !isAmp && useMembrana && membranaOptions.enableMobile && i === adsOptions.positionMobile * membranaOptions.positionMobile;

  const shouldRenderTruvidDesktop = (i = +1) =>
    !isAmp && useTruvid && truvidOptions.enableDesktop && i === adsOptions.positionDesktop * truvidOptions.positionDesktop;
  const shouldRenderMembranaDesktop = (i = +1) =>
    !isAmp && useMembrana && membranaOptions.enableDesktop && i === adsOptions.positionDesktop * membranaOptions.positionDesktop;

  const shouldRenderAmpAdFlyingCarpet = (i = +1) => isAmp && useTruvid && ampAdFlying.enable && i === adsOptions.positionMobile * ampAdFlying.position;

  const contentRearranged = [];
  let adMobileCount = 1;
  let adDesktopCount = 1;

  const adTruvidM = { tipo: 'adTruvidMobile' };
  const adTruvidD = { tipo: 'adTruvidDesktop' };
  const adMembranaM = { tipo: 'adMembranaMobile' };
  const adMembranaD = { tipo: 'adMembranaDesktop' };

  const adAmpFlyingCarpet = { tipo: 'adAmpFlyingCarpet' };

  content.forEach((block, i) => {
    const adMobile = { tipo: 'adMobile', idCountPosition: shouldUseIdCountPosition && adMobileCount <= maxIdCountPosition ? adMobileCount.toString() : '' };
    const adDesktop = { tipo: 'adDesktop', idCountPosition: shouldUseIdCountPosition && adDesktopCount <= maxIdCountPosition ? adDesktopCount.toString() : '' };

    if (content.length >= 4 && i === 4) {
      contentRearranged.push({ tipo: 'cardCTA' });
    }

    if (i === 0) {
      contentRearranged.push(adMobile);
      adMobileCount += 1;
    }

    if (i === 0 && isAmp) {
      contentRearranged.push(adDesktop);
      adDesktopCount += 1;
    }

    if (isValidMobile(i) && shouldRenderTruvidMobile(i) && !shouldRenderMembranaMobile(i)) contentRearranged.push(adTruvidM);
    if (isValidMobile(i) && shouldRenderMembranaMobile(i) && !shouldRenderTruvidMobile(i)) contentRearranged.push(adMembranaM);
    if (isValidMobile(i) && !shouldRenderTruvidMobile(i) && !shouldRenderMembranaMobile(i) && !shouldRenderAmpAdFlyingCarpet(i)) {
      if (adMobileCount <= maxIdCountPosition) contentRearranged.push(adMobile);
      if (adMobileCount <= maxIdCountPosition) adMobileCount += 1;
    }
    if (shouldRenderAmpAdFlyingCarpet(i)) contentRearranged.push(adAmpFlyingCarpet);

    if (isValidDesktop(i) && shouldRenderTruvidDesktop(i) && !shouldRenderMembranaDesktop(i)) contentRearranged.push(adTruvidD);
    if (isValidDesktop(i) && shouldRenderMembranaDesktop(i) && !shouldRenderTruvidDesktop(i)) contentRearranged.push(adMembranaD);
    if (isValidDesktop(i) && !shouldRenderTruvidDesktop(i) && !shouldRenderMembranaDesktop(i) && !shouldRenderAmpAdFlyingCarpet(i)) {
      if (adDesktopCount <= maxIdCountPosition) contentRearranged.push(adDesktop);
      if (adDesktopCount <= maxIdCountPosition) adDesktopCount += 1;
    }

    contentRearranged.push(block);

    if (content.length > 9 && i === 8 && !isAmp) {
      contentRearranged.push({ tipo: 'readMore' });
    }
  });

  return contentRearranged;
}

interface AdsContains {
  ads: boolean;
  mobile: boolean;
}

const adsContainsMobile: AdsContains = {
  ads: true,
  mobile: true,
};

const adsContainsDesktop: AdsContains = {
  ads: true,
  mobile: false,
};

interface listingRearrangerProps<Type> {
  content: Type[];
  primary: boolean;
  hasNoAds?: boolean;
  sizeMobile?: number;
  sizeDesktop?: number;
}

export function listingRearranger<Type>({ content, primary, hasNoAds = false, sizeMobile = 3, sizeDesktop = 6 }: listingRearrangerProps<Type>) {
  const length = content?.length;
  const result = [];

  if (!primary) {
    result.push(adsContainsDesktop);
  }

  let chunkified = [];

  content.forEach((element: Type, index: number) => {
    chunkified.push(element);

    if ((index + 1) % sizeMobile === 0 && index + 1 >= sizeMobile && !hasNoAds) chunkified.push(adsContainsMobile);
    if ((index + 1) % sizeDesktop === 0 && index + 1 >= sizeDesktop) {
      result.push({ chunck: chunkified });
      if (index + 1 < length && !hasNoAds) result.push(adsContainsDesktop);
      chunkified = [];
    }
    if (chunkified.length > 0 && length === index + 1) {
      result.push({ chunck: chunkified });
    }
  });

  return result as ({ chunck: (Type | AdsContains)[] } | AdsContains)[];
}

export default { contentRearranger, listingRearranger };
