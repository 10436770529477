/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Fragment } from 'react';
import { TimeLine } from '@/types/types';
import AdsGeneratorMemo from '@/components/Ads/Google/AdsTypeGeneratorsMemo';
import NewsTimeLine, { PropsAutor, PropsMetaInfo, PropsTitle } from '../NewsTimeLine';
import PageSpotter from '../PageSpotter';
import WrapperContent from '../WrapperContent';
import { ContentListNews, BillboardContainer } from './styles';
import { mglBlue } from '@/styles/designSystemColors';

const PropMetaInfo: PropsMetaInfo = {
  age: true,
  category: true,
};

const PropAutor: PropsAutor = {
  image: true,
  imgInImage: true,
  text: false,
};

interface ItemListProps {
  items: Array<{ chunck: (TimeLine | { ads: boolean; mobile: boolean })[] } | { ads: boolean; mobile: boolean }>;
  page?: number;
  useServerSide?: boolean;
}

export default function ItemListNews({ items, page, useServerSide = true }: ItemListProps) {
  const pagePrimary = items.length === 3;

  const PropTitle: PropsTitle = {
    altColorInHover: true,
    colorHover: mglBlue.mglBlue400,
  };

  let pagination = page;
  if (useServerSide) pagination = pagePrimary ? page : page + 1;

  return (
    <>
      <WrapperContent isOnHeader>
        {items.map((item, index) =>
          'chunck' in item ? (
            <PageSpotter page={pagination} key={index}>
              <ContentListNews>
                {item.chunck.map((timeline, nestedIndex) => (
                  <Fragment key={nestedIndex}>
                    {!('ads' in timeline) ? (
                      <NewsTimeLine
                        news={timeline}
                        title
                        propsTitle={PropTitle}
                        effectImage
                        metaInfo
                        propsMetaInfo={PropMetaInfo}
                        autor
                        propsAutor={{ ...PropAutor, imgRounded: timeline.autor !== 'Lu do Magalu' }}
                      />
                    ) : (
                      <AdsGeneratorMemo key={nestedIndex} type="rectangle/main/listing" />
                    )}
                  </Fragment>
                ))}
              </ContentListNews>
            </PageSpotter>
          ) : (
            <BillboardContainer key={index}>
              <AdsGeneratorMemo type="billboard/main/listing" />
            </BillboardContainer>
          )
        )}
      </WrapperContent>
    </>
  );
}
