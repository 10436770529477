export type sponsoredCategoryType = 'mercado' | 'seguranca';
export type editorialCategoryType = 'ctup' | 'ctauto' | 'eletro';

type image = {
  src: string;
  blurhash: string;
  height: number;
  width?: number;
};

export type categorySponsorDataItemType = {
  url: string;
  title: string;
  image: {
    mobile: image;
    desktop: image;
  };
};

type categorySponsorDataType = {
  mercado: categorySponsorDataItemType[];
  seguranca: categorySponsorDataItemType[];
};

export const sponsorCategoryData: categorySponsorDataType = {
  mercado: [
    {
      url: 'https://www.magazinevoce.com.br/cadastro/?utm_source=canaltech_categoria&utm_medium=canaltech_redes&utm_id=https%3A%2F%2Fwww.magazinevoce.com.br%2Fcadastro%2F',
      title: 'Ir para o site do Parceiro Magalu!',
      image: {
        mobile: {
          src: 'https://t.ctcdn.com.br/Zqk765SIgqbC_dIX4g_vYoIULag=/i922055.png',
          blurhash: 'LwFOo-^+S}Nv~W?HS~S3%2xuXSWX',
          height: 26,
          width: 37,
        },
        desktop: {
          src: 'https://t.ctcdn.com.br/YW4xwvk9GzU2LCI2ngfJgEsfFuo=/i923101.png',
          blurhash: 'L$ODU@Z%m,n5~WnOi_s+VoW;X8X8',
          height: 26,
          width: 102,
        },
      },
    },
  ],
  seguranca: [
    {
      url: 'https://get.surfshark.net/aff_c?aff_id=4652&aff_sub=banner2024&offer_id=926',
      title: 'Ir para o site do Surfshark!',
      image: {
        mobile: {
          src: 'https://t.ctcdn.com.br/DYssMNMX6Inr9NxCyYKJPX81h-c=/i922057.png',
          blurhash: 'LuFFml.7J6w}_4%fSzw|Wsoxxuso',
          height: 26,
          width: 22,
        },
        desktop: {
          src: 'https://t.ctcdn.com.br/A-h4cyDLl2JnKHeQdEiMGQBnr44=/i923099.png',
          blurhash: 'L+M81xJQS~Nb_NR*RjbHjEaeRjo2',
          height: 26,
          width: 112,
        },
      },
    },
  ],
};

export const enableSponsoredCategories = ['mercado', 'seguranca'];

export const sponsoredCategoriesMarketUri = [
  'mercado',
  'empreendedorismo',
  'startup',
  'e-commerce',
  'resultados-financeiros',
  'negocios',
  'bolsa-de-valores',
  'publicidade',
  'juridico',
  'criptomoedas',
];

export const sponsoredCategoriesSecurityUri = ['seguranca', 'blockchain', 'espionagem', 'hacker'];

const editorialCategoriesCtupUri = ['hardware', 'games', 'ctup', 'notebook'];

const sponsoredCategoriesMarketTitle = [
  'Mercado',
  'Bolsa de Valores',
  'Criptomoedas',
  'E-commerce',
  'Empreendedorismo',
  'Jurídico',
  'Negócios',
  'Publicidade',
  'Resultados financeiros',
  'Startup',
];

const sponsoredCategoriesSecurityTitle = ['Blockchain', 'Espionagem', 'Hacker', 'Segurança'];

const editorialCategoriesCtupTitle = ['Hardware', 'Games', 'Notebook'];

const editorialCategoriesCtAutoTitle = ['Veículos', 'Carros', 'Mobilidade Urbana', 'Veículos Autônomos'];

export const getSponsoredCategory = singleCategory => {
  if (sponsoredCategoriesMarketUri.includes(singleCategory)) return 'mercado';
  if (sponsoredCategoriesSecurityUri.includes(singleCategory)) return 'seguranca';
  return undefined;
};

export const getEditorialCategory = singleCategory => {
  if (editorialCategoriesCtupUri.includes(singleCategory)) return 'ctup';
  if (singleCategory === 'eletro') return 'eletro';
  if (singleCategory === 'ctauto') return 'ctauto';
  return undefined;
};

export const isSponsoredOrEditorialCategoryWithStringToInternal = (mainCategory, categories) => {
  if (sponsoredCategoriesMarketTitle.includes(mainCategory)) return 'sponsored';
  if (sponsoredCategoriesSecurityTitle.includes(mainCategory)) return 'sponsored';

  if (editorialCategoriesCtupTitle.includes(mainCategory)) return 'editorial';
  if (editorialCategoriesCtAutoTitle.includes(mainCategory)) return 'editorial';
  if (mainCategory === 'CT Eletro') return 'editorial';

  if (categories.some(categoria => sponsoredCategoriesMarketTitle.includes(categoria))) return 'sponsored';
  if (categories.some(categoria => sponsoredCategoriesSecurityTitle.includes(categoria))) return 'sponsored';

  if (categories.some(categoria => editorialCategoriesCtupTitle.includes(categoria))) return 'editorial';
  if (categories.some(categoria => editorialCategoriesCtAutoTitle.includes(categoria))) return 'editorial';
  if (categories.includes('CT Eletro')) return 'editorial';
  return undefined;
};

export const getSponsoredCategoryWithStringToInternal = (categories: string[], mainCategory: string) => {
  let category = undefined;
  if (mainCategory && sponsoredCategoriesMarketTitle.includes(mainCategory)) category = 'mercado';
  if (mainCategory && sponsoredCategoriesSecurityTitle.includes(mainCategory)) category = 'seguranca';

  if (categories.some(categoria => sponsoredCategoriesMarketTitle.includes(categoria))) category = 'mercado';
  if (categories.some(categoria => sponsoredCategoriesSecurityTitle.includes(categoria))) category = 'seguranca';

  if (!enableSponsoredCategories.includes(category)) return undefined;

  return category;
};
export const getEditorialCategoryWithStringToInternal = (categories: string[], mainCategory: string) => {
  let category = undefined;
  if (categories.some(categoria => editorialCategoriesCtupTitle.includes(categoria))) category = 'ctup';
  if (categories.some(categoria => editorialCategoriesCtAutoTitle.includes(categoria))) category = 'ctauto';
  if (categories.includes('CT Eletro')) category = 'eletro';

  if (editorialCategoriesCtupTitle.includes(mainCategory)) category = 'ctup';
  if (editorialCategoriesCtAutoTitle.includes(mainCategory)) category = 'ctauto';
  if (mainCategory === 'CT Eletro') category = 'eletro';
  return category;
};
