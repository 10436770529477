import classNames from '@/utils/className';

interface WrapperProps {
  backgroundColor?: string;
  isOnHeader?: boolean;
  paddingOnBottom?: boolean;
  children: JSX.Element[] | JSX.Element;
}

export default function WrapperContentLastNews({ children, backgroundColor = 'bg-mglGray50', isOnHeader = false, paddingOnBottom = false }: WrapperProps) {
  const variants = {
    isOnHeader: {
      true: 'isOnHeader [&.isOnHeader]:p-0',
      false: '',
    },
    paddingOnBottom: {
      true: 'paddingOnBottom [&.paddingOnBottom]:pb-10',
      false: '',
    },
  } as const;

  return (
    <section
      className={classNames(
        'flex w-full flex-col flex-wrap content-center justify-center p-0 py-[40px]',
        backgroundColor && backgroundColor,
        variants.isOnHeader[isOnHeader.toString()],
        variants.paddingOnBottom[paddingOnBottom.toString()]
      )}>
      {children}
    </section>
  );
}
